.carousel-index-indicator{
    position: absolute;
    z-index: 10;
    right: 10px; 
    top: 10px; 
    background: rgba(0,0,0,0.25); 
    padding: 0px 10px 0px 10px;
    color:antiquewhite;
    font-weight: 600;
    font-size: 12px;
}